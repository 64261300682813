import Clipboard from 'stimulus-clipboard'

export default class extends Clipboard {
  static targets = ['button', 'source', 'template']

  connect() {
    super.connect()
  }

  copy() {
    event.preventDefault()

    const text = this.sourceTarget.innerText || this.sourceTarget.value

    navigator.clipboard.writeText(text).then(() => this.copied())
  }

  // Function to override when to input is copied.
  copied() {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = this.templateTarget.innerHTML

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent
    }, this.successDurationValue)
  }
}
