import { Controller } from "@hotwired/stimulus"
import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  connect() {
    super.connect();
    this.initializeGroupIcon();
  }

  initializeGroupIcon() {
    const icon = this.calendarIcon;
    icon.addEventListener('click', () => { this.fp.toggle() })
  }

  get calendarIcon() {
    return this.flatpickrElement.parentNode.getElementsByClassName('input-group-prepend')[0];
  }
}
