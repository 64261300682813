import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  startConversation(event) {
    event.preventDefault()
    const element = event.target.parentElement;
    const sgid = element.querySelector('span').getAttribute('sgid');
    const editorElement = document.getElementById('publisher_pitch_comment_content');
    const attachment = new Trix.Attachment({ content: element.innerHTML, sgid: sgid});
    editorElement.editor.insertAttachment(attachment);
  }
}
