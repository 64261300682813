import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'counter']
  static values = { countdown: Boolean, maxlength: Number }

  connect() {
    this.inputTarget.addEventListener('trix-change', (event) => this.update(event))
    this.inputTarget.addEventListener('trix-initialize', (event) => this.update(event))
  }

  disconnect() {
    this.inputTarget.removeEventListener('trix-change', this.update)
    this.inputTarget.removeEventListener('trix-initialize', this.update)
  }

  update(event) {
    const { editor } = event.target;
    const characterCount = this.count(editor);
    const trixDocument = editor.getDocument()

    this.counterTarget.innerHTML = characterCount.toString();

    if (this.maxLength === 0) return

    if (characterCount > this.maxLength) {
      if (this.trixDocument) editor.loadDocument(this.trixDocument)
    } else {
      this.trixDocument = trixDocument
    }
  }

  count(editor) {
    const string = editor.getDocument().toString();
    const characterCount = string.length - 1;

    return characterCount;
  }

  get maxLength() {
    return this.maxlengthValue;
  }
}
