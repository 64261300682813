import { Controller } from "@hotwired/stimulus"
import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static targets = ['input', 'template']

  initialize() {
    this.boundHandleAutocompleteUpdate = this.handleAutocompleteUpdate.bind(this);
  }

  connect() {
    super.connect();
    window.addEventListener('autocomplete.change', this.boundHandleAutocompleteUpdate);;
  }

  disconnect() {
    window.removeEventListener('autocomplete.change', this.boundHandleAutocompleteUpdate);
  }

  handleAutocompleteUpdate(event) {
    this.inputTarget.value = '';
    const { value: countryId, textValue: countryName } = event.detail;
    const creationDate = new Date().getTime().toString()
    let content = '';
    content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, creationDate);
    content = content.replace(/COUNTRY_ID/g, countryId);
    content = content.replace(/COUNTRY_NAME/g, countryName);

    this.targetTarget.insertAdjacentHTML("beforebegin", content);
    const newEvent = new CustomEvent("rails-nested-form:add", { bubbles: true })
    this.element.dispatchEvent(newEvent);
  }
}
