import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-all"
export default class extends Controller {
  static targets = [ "main", "child" ]

  connect() {
    this.mainListener = this.update.bind(this)
    this.mainTarget.addEventListener('input', this.mainListener);
    this.childListener = this.sync.bind(this)
    this.childTargets.forEach(target => target.addEventListener('input', this.childListener));
    this.sync();
  }

  disconnect() {
    this.mainTarget.removeEventListener('input', this.mainListener);
    this.childTargets.forEach(target => target.removeEventListener('input', this.childListener));
  }

  update() {
    const checked = !!this.mainTarget.checked;
    this.childTargets.forEach(target => target.checked = checked);
  }

  sync() {
    const checked = this.childTargets.every(target => !!target.checked);
    this.mainTarget.checked = checked;
  }
}
