import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['form']
  static values = { frame: String }

  sendMessage(event) {
    const text = event.target.value;
    if (!text) return;

    Rails.fire(this.formTarget, 'submit');
  }

  showDeveloperInfo(event) {
    event.preventDefault();
    const targetDiv = document.querySelector('.inbox-about-publisher-wrapper');
    targetDiv.hidden = !targetDiv.hidden;
    const targetFrame = document.querySelector('#developer_info');
    targetFrame.src = this.frameValue;
  }

  showPublisherInfo(event) {
    event.preventDefault();
    const targetDiv = document.querySelector('.inbox-about-publisher-wrapper');
    targetDiv.hidden = !targetDiv.hidden;
    const targetFrame = document.querySelector('#publisher_info');
    targetFrame.src = this.frameValue;
  }
}
