import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['pc', 'console', 'other']
  static outlets = ['visibility']

  connect() {
    super.connect();
  }

  toggleOutlets(event) {
    const params = event.params;
    const target = event.target;
    const platform = params.platform;
    let hideSection = false;
    this.visibilityOutlets.forEach(monetization => {
      if (monetization.categoriesValue.includes(platform)) {
        const monetizationElement = monetization.element;
        if (target.checked) {
          monetization.showTargets();
        } else {
          if (['PC', 'Mac'].includes(platform)) {
            const checkStatuses = Array.from(this.pcTargets.map(el => { return el.checked }))
            const notClosable = checkStatuses.includes(true);
            if (notClosable) return;
            monetization.hideTargets();
            return;
          }
          if (['Xbox', 'Play Station', 'Nintendo Switch'].includes(platform)) {
            const checkStatuses = Array.from(this.consoleTargets.map(el => { return el.checked }))
            const notClosable = checkStatuses.includes(true);
            if (notClosable) return;
            monetization.hideTargets();
            return;
          }
          monetization.hideTargets()
        }
      }
    })
    this.monetizationSection.hidden = !this.hasCheckedOptions;
  }

  toggleAllOutlets(event) {
    const params = event.params;
    const target = event.target;
    this.visibilityOutlets.forEach(monetization => {
      if (target.checked) {
        monetization.showTargets();
      } else {
        monetization.hideTargets();
      }
    })
    this.monetizationSection.hidden = !this.hasCheckedOptions;
  }

  get hasCheckedOptions() {
    return Array.from(this.pcTargets.concat(this.consoleTargets).concat(this.otherTargets)).some((el) => el.checked);
  }

  get monetizationSection() {
    return document.getElementById('monetization-section');
  }
}
