import { initializePaddle, Paddle } from '@paddle/paddle-js';
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['errorTemplate']
  static values = {
    token: String,
    environment: String,
    priceId: String,
    successUrl: String,
    email: String,
    companyName: String,
    gamePitchUuid: String,
    completeCheckoutUrl: String,
  }

  async initialize() {
    await initializePaddle({
      environment: this.environmentValue,
      token: this.tokenValue,
      checkout: {
        settings: {
          displayMode: "inline",
          frameTarget: "checkout-container",
          frameInitialHeight: "450",
          frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;",
          theme: 'dark',
          variant: 'one-page'
          // successUrl: this.successUrlValue
        }
      },
      eventCallback: (data) => {
        if (data.name == "checkout.completed") {
          console.log(data);
          const params = new URLSearchParams();
          params.append('paddle_transaction_id', data['data']['transaction_id']);

          fetch(this.completeCheckoutUrlValue, {
            method: 'POST',
            body: params,
            headers: {
              'X-CSRF_Token': document.head.querySelector("meta[name=csrf-token]").content,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'follow',
          })
            .then(response => {
              return response;
            })
            .then(response => {
              if (response.status == 200) {
                window.location = response.url
              } else {
                console.error("There was a problem with the post-payment processing.")
                this.showError();
              }
            })
            .catch(error => {
              this.showError();
            })
        }
      }
    }).then((paddleInstance) => {
      if (paddleInstance) {
        this.paddle = paddleInstance;
      }
    }).catch(error => {
      console.error(error);
    })
  }

  showError() {
    const alert = this.errorTemplateTarget.content.firstElementChild.cloneNode(true);
    this.element.prepend(alert);
  }

  checkout(event) {
    event.preventDefault();
    event.target.disabled = true;
    event.target.hidden = true;
    const comparisonElement = document.querySelector('.pre-paywall-features-section');
    comparisonElement.classList.add('d-none');

    this.paddle.Checkout.open({
      items: [{ priceId: this.priceIdValue, quantity: 1 }],
      customer: {
        email: this.emailValue,
        business: {
          name: this.companyNameValue
        }
      },
      customData: {
        purchase_source: 'GameScout WebApp' ,
        game_pitch_uuid: this.gamePitchUuidValue
      }
    });
  }

  openCheckout() {
    this.element.hidden = true;
    this.paddle.Checkout.open({
      items: [{ priceId: this.priceIdValue, quantity: 1 }],
      customer: {
        email: this.emailValue,
        business: {
          name: this.companyNameValue
        }
      },
      customData: {
        purchase_source: 'GameScout WebApp' ,
        game_pitch_uuid: this.gamePitchUuidValue
      }
    });
  }

}
