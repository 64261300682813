import NestedForm from 'stimulus-rails-nested-form';
import anyBase from 'any-base';

var converter = anyBase(anyBase.DEC, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ');

export default class extends NestedForm {
  static targets = ['deleteButton']
  static values = {
    maxEmployeesCount: { type: Number, default: 6 }
  }

  connect() {
    super.connect();
    this.wrapperSelectorValue = '.employee-wrapper';
    this.titleSelectorValue = '.employee-title';
    this.updateTitles();
    this.toggleAddButtonState();
    this.disableFirstDeleteButton();
  }

  add(event) {
    super.add(event);
    this.toggleAddButtonState();
    this.updateTitles();
  }

  toggleAddButtonState() {
    if (this.maxEmployeesCountValue <= this.activeEmployeesCount()) {
      this.targetTarget.disabled = true;
    } else {
      this.targetTarget.disabled = false;
    }
  }

  activeEmployeesCount() {
    const elements = this.element.querySelectorAll(this.wrapperSelectorValue);
    const employeesCount = [...elements].filter((element) => {
      const isDeleted = element.getAttribute('deleted');
      return isDeleted !== 'true';
    }).length;
    return employeesCount;
  }

  remove (event) {
    super.remove(event);
    const wrapper = event.target.closest(this.wrapperSelectorValue);
    wrapper.setAttribute('deleted', true);
    this.toggleAddButtonState();
    this.updateTitles();
  }

  updateTitles() {
    Array.from(this.element.querySelectorAll(this.titleSelectorValue))
      .forEach((element, index) =>  {
        element.innerHTML = `Person ${converter(index.toString())}`;
      });
  }

  disableFirstDeleteButton() {
    const firstDeleteButton = this.deleteButtonTargets[0];
    if (firstDeleteButton) firstDeleteButton.disabled = true;
  }
}
