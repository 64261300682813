import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['link', 'tab']

  activate(event) {
    const link = event.target.closest('.team-tab-div');

    this.linkTargets.forEach((target) => {
      target.classList.remove('w--current')
    })

    link.classList.add('w--current');

    this.tabTargets.forEach((target) => {
      target.classList.remove('w--tab-active')
    })

    this.linkTargets.forEach((target, index) => {
      if (target.classList.contains('w--current')) {
        this.tabTargets[index].classList.add('w--tab-active')
      }
    })
  }
}
