import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static values = { url: String }

  navigate(event) {
    Turbo.visit(this.urlValue);
  }
}
