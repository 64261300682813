import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "fileInput", "placeholder", "template", "trash", "hiddenInput" ]
  static values = {
    truncateFileName: { type: Boolean, default: false },
    maxFileNameSize: { type: Number, default: 28 }
  }

  open(event) {
    event.preventDefault();
    this.fileInputTarget.click();
  }

  update() {
    const input = this.fileInputTarget;
    const files = this.fileInputTarget.files;
    const url = this.fileInputTarget.dataset.directUploadUrl;

    if (files.length === 0) {
      this.placeholderTarget.classList.remove('d-none');
    } else {
      if (url) {
        const upload = new DirectUpload(files[0], url);

        upload.create((error, blob) => {
          if (error) {
            console.warn('Direct upload to storage failed!');
            console.warn(error);
          } else {
            this.hiddenInputTarget.setAttribute("value", blob.signed_id);
            this.placeholderTarget.innerHTML = this.truncateFileName(files[0].name);
            this.trashTarget.disabled = false;
          }
        })
      }
    }
  }

  delete(event) {
    event.preventDefault();
    this.hiddenInputTarget.setAttribute('value', '');
    const inputTemplate = this.templateTarget.content.firstElementChild.cloneNode(true);
    this.placeholderTarget.innerHTML = "";
    this.placeholderTarget.prepend(inputTemplate);
    this.trashTarget.disabled = true;
  }

  truncateFileName(fileName) {
    if (this.truncateFileNameValue) {
      return `${fileName.slice(0, this.maxFileNameSizeValue)}...`;
    } else {
      return fileName;
    }
  }
}
