import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['destination', 'name']

  reveal(target) {
    if (target) target.preventDefault();

    this.nameTarget.hidden = true;
    this.destinationTarget.hidden = false;
    this.destinationTarget.focus();
  }

  restore(target) {
    target.preventDefault();

    this.nameTarget.hidden = false;
    this.nameTarget.innerText = this.destinationTarget.value;
    this.destinationTarget.hidden = true;

    if (this.destinationTarget.value === '') {
      const button = this.destinationTarget.parentElement.querySelector('button.btn-close.btn-close-white');
      button.click();
    }
  }
}
