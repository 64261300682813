import { Application } from "@hotwired/stimulus"
import 'trix'
import "@rails/actiontext"
import '@thoughtbot/trix-mentions-element'

const application = Application.start()

application.debug = false;
window.Stimulus   = application;

export { application }
