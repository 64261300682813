import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static values = {
    titleText: { type: String, default: 'Milestone #' },
    titleSelector: { type: String, default: '.milestone-title' }
  }

  connect() {
    super.connect();
    this.updateTitles();
  }

  add (event) {
    super.add(event);
    this.updateTitles();
  }

  remove (event) {
    super.remove(event);
    this.updateTitles();
  }

  updateTitles() {
    Array.from(this.element.querySelectorAll(this.titleSelectorValue))
      .filter((element) => element.offsetWidth !== 0 || element.offsetHeight !== 0)
      .forEach((element, index) => element.innerHTML = `${this.titleTextValue}${(index + 1).toString()}`);
  }
}
