import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="exclusive_checkbox"
export default class extends Controller {
  static targets = ['first', 'second', 'firstSelect', 'secondSelect']

  select() {
    this.firstSelectTargets.forEach((element) => {
      const checked = this.firstTarget.checked
      if (checked === false) {
        element.checked = checked
      }
    })
    this.secondSelectTargets.forEach((element) => {
      const checked = this.secondTarget.checked
      if (checked === false) {
        element.checked = checked
      }
    })
  }
}
