import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { frame: String }

  change(event) {
    const frame = document.getElementById(this.frameValue);
    frame.src = event.target.value;
  }
}
