import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static targets = ['addButton']
  static values = {
    maxGamesCount: { type: Number, default: 3 },
    titleSelector: { type: String, default: '.game-title' }
  }

  connect() {
    super.connect();
    this.updateTitles();
    this.toggleAddButtonState();
  }

  add (event) {
    super.add(event);
    this.toggleAddButtonState();
    this.updateTitles();
  }

  toggleAddButtonState() {
    if (this.maxGamesCountValue <= this.activeGamesCount) {
      this.addButtonTarget.disabled = true;
    } else {
      this.addButtonTarget.disabled = false;
    }
  }

  get activeGamesCount() {
    const elements = this.element.querySelectorAll(this.wrapperSelectorValue);
    const gamesCount = [...elements].filter((element) => {
      const isDeleted = element.getAttribute('deleted');
      return isDeleted !== 'true';
    }).length;
    return gamesCount;
  }

  remove(event) {
    super.remove(event);
    const wrapper = event.target.closest(this.wrapperSelectorValue);
    wrapper.setAttribute('deleted', true);
    this.toggleAddButtonState();
    this.updateTitles();
  }

  updateTitles() {
    Array.from(this.element.querySelectorAll(this.titleSelectorValue))
      .filter((element) => element.offsetWidth !== 0 || element.offsetHeight !== 0)
      .forEach((element, index) => element.innerHTML = `Game ${index + 1}`);
  }
}
