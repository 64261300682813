import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['option']

  connect () {
    this.optionListener = this.select.bind(this)
    this.optionTargets.forEach(target => target.addEventListener('input', this.optionListener));
  }

  select() {
    this.optionTargets.forEach(target => {
      if (target !== event.target) {
        target.checked = false
      }
    })
  }
}
