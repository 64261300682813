import { Controller } from "@hotwired/stimulus"
import { Alert } from "bootstrap"

export default class extends Controller {
  static targets = ['dismiss']

  initialize() {
    this.boundAutoClose = this.autoClose.bind(this);
  }

  connect() {
    super.connect();
    this.autoClose();
  }

  autoClose() {
    const that = this;
    setTimeout(function() {
      that.dismissTargets.map((target) => {
        alert = new Alert(target);
        alert.close();
      })
    }, 7000)
  }
}
