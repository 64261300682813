import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['filterDestination']

  connect() {
    this.filterModal = new Modal('#filterDestinationModal', {
      keyboard: true,
      backdrop: true,
    })
  }

  showFilterOptions(event) {
    event.preventDefault();
    const path = event.target.href;
    fetch(path)
      .then(response => response.text())
      .then(html => {
        this.filterDestinationTarget.hidden = false;
        this.filterDestinationTarget.innerHTML = html;
        this.filterModal.show();
      }
    )
  }

  close(event) {
    this.filterModal.hide();
  }

  reset(event) {
    console.log('Resetting the controls...');
    event.preventDefault();
    this.element.querySelectorAll('input').forEach( (inputElement) => {
      inputElement.checked = false;
      if (inputElement.getAttribute('type') === 'text') inputElement.value = '';
    })
  }

  apply(event) {
    const filterButton = document.querySelector('.btn-filter');
    if (this.hasSomeSelection()) {
      filterButton.classList.add('active');
      filterButton.innerText = 'Filter active';
    } else {
      filterButton.classList.remove('active');
      filterButton.innerText = 'Filter';
    }
    this.filterModal.hide();
  }

  hasSomeSelection() {
    return Array.from(this.element.querySelectorAll('input')).some((inputElement) => {
      if (inputElement.getAttribute('type') === 'text') {
        return inputElement.value;
      }
      return inputElement.checked;
    });
  }
}
