import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['rateField', 'form']

  setRate(event) {
    event.preventDefault();
    const rate = event.target.value;
    this.rateFieldTarget.value = rate;
    this.formTarget.requestSubmit();
  }
}
