import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  add(event) {
    const parentElement = event.target.closest('div.wizard-tag-wrapper');
    super.add(event);
    const nodes = parentElement.querySelectorAll('input.suggest-another-input');
    const lastInput = nodes[nodes.length - 1];
    lastInput.focus();
  }
}
