import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['limitable']
  static values = {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 5 },
    integer: { type: Boolean, default: false }
  }

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {
    this.limitableTarget.addEventListener('input', this.update)
    if (this.integerValue) this.limitableTarget.addEventListener('keydown', this.blockNonIntegers)
  }

  blockNonIntegers() {
    const intRx = /\d/

    if (event.key.length > 1 || intRx.test(event.key) || event.key === "-") return

    event.preventDefault()
  }

  update(event) {
    const value = this.limitableTarget.value

    if (value < this.minValue) {
      this.limitableTarget.value = this.minValue
    }
    if (value > this.maxValue) {
      this.limitableTarget.value = this.maxValue
    }
  }
}
