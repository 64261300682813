import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static values = { selector: String }

  connect() {
    useIntersection(this)
  }

  appear(entry) {
    if (this.hasSelectorValue) {
      const element = document.getElementsByClassName(this.selectorValue)
      if (element[0]) element[0].classList.add('dot-in-focus')
    }
  }

  disappear(entry) {
    if (this.hasSelectorValue) {
      const element = document.getElementsByClassName(this.selectorValue)
      if (element[0]) element[0].classList.remove('dot-in-focus');
    }
  }
}
