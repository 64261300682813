import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Create a unique key to store the form data into localStorage.
    // This could be anything as long as it's unique.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
    this.localStorageKey = window.location;
    this.setFormData();
  }

  getFormData() {
    // Construct a set of of key/value pairs representing form fields and their values.
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData
    const form = new FormData(this.element);
    const data = {};

    // Loop through each key/value pair.
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/entries#example
    for (const pair of form.entries()) {
      // We don't want to save the authenticity_token to localStorage since that is generated by Rails.
      // https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf
      console.log(pair);
      const field = pair[0];
      const value = pair[1];
      if (field === "authenticity_token" || field === '_method') {
        console.log('Skipping fields');
        console.log(field);
      } else {
        let currentValue = data[field];
        currentValue ? currentValue.push(value) : currentValue = [value];
        data[field] = currentValue;
      }
    }

    return data;
  }

  clearLocalStorage() {
    // See if there is data stored for this particular form.
    if (localStorage.getItem(this.localStorageKey) != null) {
      // Clear data from localStorage when the form is submitted.
      localStorage.removeItem(this.localStorageKey);
    }
  }

  saveToLocalStorage() {
    const data = this.getFormData();
    // Save the form data into localStorage. We need to convert the data Object into a String.
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }

  setFormData() {
    // See if there is data stored for this particular form.
    if (localStorage.getItem(this.localStorageKey) != null) {
      // We need to convert the String of data back into an Object.
      const data = JSON.parse(localStorage.getItem(this.localStorageKey));
      console.log(data);
      const formEl = this.element;
      const form = new FormData(this.element);
      // Loop through each key/value pair and set the value on the corresponding form field.
      Object.entries(data).forEach((entry) => {
        let name = entry[0];
        let value = entry[1];
        console.log(name);
        console.log(value);
        const formElements = formEl.querySelectorAll(`[name='${name}']`);

        if (formElements.length == 1) {
          const elem = formElements[0];
          const elValue = value[0];
          if (elem.getAttribute('type') !== 'file') elem.value = elValue;
          if (elem.type == 'checkbox' && elValue === '') elem.checked = true;
        } else {
          value.forEach((val) => {
            if (val !== '') {
              const elem = formEl.querySelector(`[name='${name}'][value='${val}']`);

              if (elem && elem.type == 'checkbox') elem.checked = true;
              if (elem && elem.type == 'radio') elem.checked = true;
            }
          })
        }
      });
    }
  }
}

