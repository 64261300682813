import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

import trashIconSource from "../../assets/images/trash.svg"

export default class extends Controller {
  static targets = ["files", "fileInput", "file", "uploadFileButton"]
  static values = {
    maxImagesCount: { type: Number, default: 6 }
  }

  get imagesLeft() {
    return this.maxImagesCountValue - this.fileTargets.length
  }

  connect() {
    this.checkDisableButton();
  }

  checkDisableButton() {
    if (this.imagesLeft === 0) {
      this.uploadFileButtonTarget.disabled = true
    } else {
      this.uploadFileButtonTarget.disabled = false
    }
  }

  open(event) {
    event.preventDefault()
    this.fileInputTarget.click()
  }

  addFile(event) {
    event.preventDefault()
    const originalInput = this.fileInputTarget;
    const files = this.fileInputTarget.files;
    const limit = this.imagesLeft * -1;

    Array.from(files).slice(limit).forEach((file) => this.uploadFile(file));
    originalInput.value = null;
  }

  uploadFile(file) {
    const input = this.fileInputTarget;
    const url = this.fileInputTarget.dataset.directUploadUrl;
    const upload = new DirectUpload(file, url);

    upload.create((error, blob) => {
      if (error) {
        console.warn('Something went bananas');
        console.warn(error);
      } else {
        const outerSpan = document.createElement('span');
        outerSpan.className = "btn btn-sm btn-radio";
        outerSpan.innerHTML = file.name;
        const hiddenField = this.createHiddenInputField(blob.signed_id);
        hiddenField.name = input.name;

        outerSpan.appendChild(hiddenField);
        outerSpan.appendChild(this.createRemoveButton());
        this.filesTarget.appendChild(outerSpan);
        this.checkDisableButton();
      }
    })
  }

  remove(event) {
    event.preventDefault();
    const element = event.target.closest('.btn-sm')
    element.remove();
    this.checkDisableButton();
  }

  createRemoveButton() {
    const removeButton = document.createElement('button');
    removeButton.className = 'btn';
    removeButton.setAttribute("data-action", "multi-file-upload#remove");
    removeButton.setAttribute("data-multi-file-upload-target", "file")

    const trashIcon = document.createElement('img');
    trashIcon.setAttribute('src', trashIconSource);
    removeButton.appendChild(trashIcon);
    return removeButton;
  }

  createHiddenInputField(signed_id) {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", signed_id);
    return hiddenField;
  }
}
