import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { identifier: String }

  submitForm(event) {
    event.preventDefault()
    const href = event.target.parentElement.closest('a').getAttribute("href")
    document.getElementById(this.identifierValue).requestSubmit()
    window.location.href = href
  }
}
