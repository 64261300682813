import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['anchorItem', 'parent']

  connect() {
    this.collapse()
  }

  collapse(event) {
    this.anchorItemTargets.forEach(target => target.classList.add('d-none'));
    this.parentTarget.classList.add('collapsed')
  }

  expand(event) {
    this.anchorItemTargets.forEach(target => target.classList.remove('d-none'))
    this.parentTarget.classList.remove('collapsed')
  }

  switch(event) {
    if (this.parentTarget.classList.contains('collapsed')){
      this.expand()
    } else {
      this.collapse()
    }
  }
}
