import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  static targets = ['tooltip']

  connect() {
    this.tooltipList = this.tooltipTargets.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
  }

  disconnect() {
    this.tooltipList.forEach(tooltipElement => tooltipElement.dispose());
  }
}
