import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rating-star"
export default class extends Controller {
  static targets = ['source', 'star']

  initialize() {
     this.update = this.update.bind(this)
   }

  connect() {
    this.sourceTarget.addEventListener('input', this.update)
    this.fillStars(this.sourceTarget.value)
  }

  update() {
    const rating = this.sourceTarget.value
    this.fillStars(rating)
  }

  fillStars(rating) {
    this.starTarget.style.setProperty('--rating', rating || 0.0);
  }
}
